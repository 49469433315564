import { reset } from '@hbf/analytics';

import { removeFromStorage } from 'ha/utils/storage';

import { savedSearchLocalStore } from 'ha/modules/SavedSearches/savedSearchLocalStore';

import { MATCHMAKING_FILTERS_STORAGE_KEY } from '../FilterBasedMatchmaking/constants';

import { BILLING_INFO_STORAGE_KEY } from './constants';

export function clearUserData() {
  // reset both user id and anonymous id
  reset(true);

  removeFromStorage(BILLING_INFO_STORAGE_KEY);

  // removed Saved Search from localStorage once user logs out, so next time user can start with a clean slate
  savedSearchLocalStore.remove();

  removeFromStorage(MATCHMAKING_FILTERS_STORAGE_KEY);
}

export function clearUserDataOnNavigation() {
  window.addEventListener('beforeunload', clearUserData);
}
